import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EmptyWorkbench from './EmptyWorkbench';
import ReactFlow, {
  Connection, ConnectionLineType, ControlButton, Controls, Edge, Node, NodeDragHandler, OnEdgesDelete, OnNodesDelete, ReactFlowInstance,
  SelectionDragHandler, addEdge, updateEdge, useEdgesState, useNodesState, useReactFlow, useUpdateNodeInternals
} from 'reactflow';
import { Droppable, IObjectList, MESSAGES, NUMBER, SHARED_SCENARIO_PERMISSION, WorkbenchLayersLimit, commentBoxHeightObj, connectionLineStyle, edgeOptions } from '../../constants';
import { DASHBOARD, deleteLogo, getNewNode, hasLayers, removeDropEffect, toggleLineType, undoIcon, zoomInImg, zoomOutImg } from '../../utils';
import ZoomInOut from './ZoomInOut';
import ContextMenu from './ContextMenu';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateNodes, clearEntireWorkbench, updateEdges, updateViewPort, updateUnsavedChange, commentLength } from '../../redux/workbenchSlice';
import DsiderCustomNode from './DsiderCustomNode';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { useNavigate } from 'react-router-dom';
import { LABELS } from '../../constants/labelConstant';
import { IPropertiesResponse, useGetCommentComponentQuery, useGetComponentAllPropertiesMutation } from '../../redux/services/workbenchApis';
import { useComponentProperties } from '../../hooks/useComponentProperty';
import { Button } from 'react-bootstrap';
import CommentInput from './CommentInput';
import { useScenario } from '../../hooks/useScenario';
import useUndoRedo from '../../hooks/useUndoRedo';
import DateTime from '../shared/DateTime';
import { resetLayers, updateBreadCrumb, updateCurrentLayerID } from '../../redux/slices/layersSlice';
import { toast } from 'react-toastify';

interface IWokbenchProps {
  clearBoardAttempt: number
  clearLinkAttempt: number
  showparameterpopup: (id: Node) => void
  saveCommentCount: number
}

// eslint-disable-next-line complexity
function WorkbenchFlow(props: IWokbenchProps) {
  const { clearBoardAttempt, clearLinkAttempt, showparameterpopup, saveCommentCount } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useAppSelector(state => state.workbench.theme);
  const workbenchData = useAppSelector(state => state.workbench);
  const settingsData = useAppSelector(state => state.WorkbenchSettings);
  const { breadCrumb, currentLayerID } = useAppSelector(state => state.layersData);
  const isBaseLine = workbenchData.scenarioDetails.BaselineStatus;
  const isSharedOrLockedScenario = !!(workbenchData.scenarioDetails.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR ?? workbenchData.scenarioDetails.BaselineStatus);
  const userData = useAppSelector(state => state.auth);
  const { zoomIn, zoomOut, getZoom, getViewport, setViewport, getNodes } = useReactFlow();
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();
  const updateNodeInternals = useUpdateNodeInternals();
  const { updateObjectListData, updateWorkbenchNode, deleteCurrentLayerEdges, deleteDescendantNodes, deleteDescendantEdges } = useComponentProperties();
  const { deleteScenario } = useScenario();
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(workbenchData.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(workbenchData.edges);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [nodeDragedCount, setNodeDraggedCount] = useState(NUMBER.N0);
  const [menu, setMenu] = useState<any>({});
  const [showDeleteScenarioAlert, setShowDeleteScenarioAlert] = useState(false);
  const [draggedNodeProperties, setDraggedNodeProperties] = useState<IPropertiesResponse>();
  const [zoomPercentage, setZoomPercentage] = useState(NUMBER.N100);
  const [getComponentProperties] = useGetComponentAllPropertiesMutation();
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';
  const showCommentFlag = useAppSelector(state => state.workbench.showComment);
  const [commentNode, setCommentNode] = useState<any>({});
  const [commentNodeData, setCommentNodeData] = useState<any>([]);
  const { data: commentComponentData, refetch } = useGetCommentComponentQuery({ scenarioId }, { refetchOnMountOrArgChange: true });
  const [commentUpdates, setCommentUpdates] = useState(false);

  const handleCommentData = (commentData: any) => {
    const nodesData = workbenchData.nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          comments: []
        }
      };
    });
    let commentCount = NUMBER.N0;
    commentData?.forEach((comment: any) => {
      const index = nodesData.findIndex((firstObj: any) => firstObj.id === comment.nodeID);
      if (index !== -1) {
        commentCount = commentCount + NUMBER.N1;
        nodesData[index].data.comments.push(comment);
      }
    });
    // updated comment count only for those components that are present on workbench...
    dispatch(commentLength(commentCount));
    setCommentNodeData(nodesData);
    setLayeredNodes(nodesData);
    if (nodesData.length) {
      dispatch(updateNodes(nodesData));
    }
  };

  useEffect(() => {
    if (clearBoardAttempt > NUMBER.N0) {
      clearEntireBoard();
      dispatch(updateUnsavedChange(true));
    }
  }, [clearBoardAttempt]);


  useEffect(() => {
    if (clearLinkAttempt > NUMBER.N0) {
      clearEntireLinking();
      dispatch(updateUnsavedChange(true));
    }
  }, [clearLinkAttempt]);

  // update properties data in object list and workbench node in the store...
  useEffect(() => {
    if (draggedNodeProperties) {
      updateObjectListData(draggedNodeProperties);
      updateWorkbenchNode(draggedNodeProperties);
    }
  }, [draggedNodeProperties]);

  // update Nodes, Edges and viewPort on import JSON data
  useEffect(() => {
    if (workbenchData.nodes.length) {
      setLayeredNodes(workbenchData.nodes);
      setLayeredEdges(workbenchData.edges);
      setViewport(workbenchData.viewPort);
      updateZoomPercentage(workbenchData.viewPort.zoom);
      handleCommentData(commentComponentData);
    }
  }, [workbenchData.importCount]);

  useEffect(() => {
    setLayeredNodes(workbenchData.nodes);
    setLayeredEdges(workbenchData.edges);
    setCommentNode({});
    setNodeDraggedCount(nodeDragedCount + NUMBER.N1);
  }, [currentLayerID]);

  const setLayeredEdges = (allEdges: Edge[]) => {
    setEdges((allEdges.map((e) => {
      if (e.data?.parentNode === currentLayerID || (!e.data?.parentNode && currentLayerID === '')) {
        return {
          ...e,
          hidden: false
        };
      } else {
        return {
          ...e,
          hidden: true
        };
      }
    })
    ));
  };

  const setLayeredNodes = (allNodes: Node[]) => {
    setNodes((allNodes.map((n) => {
      if (n.data.parentNode === currentLayerID || (!n.data.parentNode && currentLayerID === '')) {
        return {
          ...n,
          hidden: false
        };
      } else {
        return {
          ...n,
          hidden: true
        };
      }
    })
    ));
  };

  // Update the nodes and edges in the redux store whenever new node and edges created or Node node dragged...
  useEffect(() => {
    if (nodes?.length) {
      dispatch(updateNodes(nodes));
      dispatch(updateEdges(edges));
    }
  }, [nodes.length, edges.length, nodeDragedCount]);

  const prevCommentUpdates = useRef(commentUpdates);

  useEffect(() => {
    if (commentUpdates !== prevCommentUpdates.current) {
      refetch();
      prevCommentUpdates.current = commentUpdates;
    } else {
      handleCommentData(commentComponentData);
    }
  }, [commentComponentData, commentUpdates, saveCommentCount]);

  // custom Node to render html inside a node...
  const nodeTypes = useMemo(() => ({ dsiderNode: DsiderCustomNode }), []);

  // Function to add edges on the workbench between two nodes...
  const onConnect = useCallback((params: any) => {
    // 👇 make adding edges undoable
    takeSnapshot();
    setEdges((eds) => addEdge({
      ...params,
      data: {
        parentNode: currentLayerID
      },
      ...edgeOptions
    }, eds));
    dispatch(updateUnsavedChange(true));
  }, [currentLayerID]);

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    // 👇 make adding edges undoable
    takeSnapshot();
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
    setNodeDraggedCount(nodeDragedCount + NUMBER.N1);
    dispatch(updateUnsavedChange(true));
  }, []);

  const onEdgeClick = (e: React.MouseEvent, edge: Edge) => {
    if (isBaseLine ?? isSharedOrLockedScenario) {
      return;
    }
    const updatedEdges = workbenchData.edges.map(ed => {
      if (ed.id === edge.id) {
        return {
          ...edge,
          type: toggleLineType(edge.type as string)
        };
      } else {
        return ed;
      }
    });
    setLayeredEdges(updatedEdges);
    dispatch(updateEdges(updatedEdges));
    dispatch(updateUnsavedChange(true));
  };

  // Function to add Droppable class on wokbench when Drag over
  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    event.currentTarget.classList.add(Droppable);
  }, []);

  // Function to remove Droppable class from wokbench when Drag end
  const onDragEnd = (e: React.DragEvent) => {
    e.preventDefault();
    removeDropEffect();
  };

  const onNodeDragStart: NodeDragHandler = useCallback(() => {
    // 👇 make dragging a node undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
    // 👇 make dragging a selection undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onNodesDelete: OnNodesDelete = useCallback(() => {
    // 👇 make deleting nodes undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onEdgesDelete: OnEdgesDelete = useCallback(() => {
    // 👇 make deleting edges undoable
    takeSnapshot();
  }, [takeSnapshot]);

  // Function to updated the drag counter so we can update the nodes postion if changed and edges in the redux store...
  const onNodeDragStop: NodeDragHandler = useCallback(() => {
    setNodeDraggedCount(nodeDragedCount + NUMBER.N1);
    dispatch(updateUnsavedChange(true));
  }, [nodeDragedCount]);

  const onMoveEnd = () => {
    updateWorkbenchViewport();
    updateZoomPercentage();
  };

  // Function to add nodes on the workbench and store...
  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();
      removeDropEffect();
      const reactFlowBounds = (reactFlowWrapper.current as HTMLElement)?.getBoundingClientRect();

      // check if the dropped element is valid
      if (!event.dataTransfer.getData('application/objectData')) {
        return;
      }
      const object: IObjectList = JSON.parse(event.dataTransfer.getData('application/objectData'));

      const position = (reactFlowInstance as any).project({
        x: event.clientX - reactFlowBounds.left - NUMBER.N80,
        y: event.clientY - reactFlowBounds.top - NUMBER.N80
      });
      const newNode = getNewNode(object, position, getNodes(), settingsData, currentLayerID);
      if (!Object.keys(newNode.data.properties)?.length) {
        getComponentProperties({ componentName: object.component, id: newNode.id }).then((res) => {
          if ('data' in res) {
            setDraggedNodeProperties(res.data);
          }
        });
      }
      // 👇 make adding nodes undoable
      takeSnapshot();
      setNodes((nds) => nds.concat(newNode));
      dispatch(updateUnsavedChange(true));
    },
    [reactFlowInstance, currentLayerID]
  );

  const takeNodesSnapshot = () => {
    // 👇 make adding nodes edges undoable
    takeSnapshot();
  };

  // Function to open context munu on right click...
  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent, node: Node) => {
      event.preventDefault();
      // Calculate position of the context menu.
      if (menuRef.current) {
        const pane = menuRef.current.getBoundingClientRect();
        const posY = event.clientY - pane.top;
        const posX = event.clientX - pane.left;
        setMenu({
          selectedNode: node,
          id: node.id,
          top: posY < pane.height - NUMBER.N250 && posY,
          left: posX < pane.width - NUMBER.N225 && posX,
          right: posX >= pane.width - NUMBER.N225 && pane.width - posX,
          bottom: posY >= pane.height - NUMBER.N250 && pane.height - posY
        });
      }
    },
    [setMenu]
  );

  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => {
    setCommentNode({});
    setMenu({});
  }, [setMenu, setCommentNode]);

  // Function to clear edges Array...
  const clearEntireLinking = useCallback(() => {
    takeNodesSnapshot();
    deleteCurrentLayerEdges(currentLayerID);
    dispatch(updateUnsavedChange(true));
  }, [currentLayerID]);

  // Function to clear nodes and edges Array...
  const clearEntireBoard = useCallback(() => {
    takeNodesSnapshot();
    if (currentLayerID) {
      deleteDescendantNodes(currentLayerID);
      deleteDescendantEdges(currentLayerID);
      dispatch(updateUnsavedChange(true));
    } else {
      setEdges([]);
      setNodes([]);
      dispatch(resetLayers());
      dispatch(clearEntireWorkbench());
    }
  }, [currentLayerID]);

  const zoomInOut = (type: any) => {
    if (type === 'zoom_in') {
      zoomIn({ duration: NUMBER.N200 });
    } else {
      zoomOut({ duration: NUMBER.N200 });
    }
    setTimeout(() => {
      updateZoomPercentage();
      updateWorkbenchViewport();
    }, NUMBER.N500);
  };

  const updateWorkbenchViewport = () => {
    dispatch(updateViewPort(getViewport()));
  };

  const updateZoomPercentage = (zoom?: number) => {
    setZoomPercentage(Math.ceil((zoom ?? getZoom() / NUMBER.N1) * NUMBER.N100));
  };

  const removeRecentScenario = () => {
    deleteScenario(scenarioId, () => {
      navigate(DASHBOARD);
    });
  };

  const onElementClick = useCallback(
    (event: React.MouseEvent, element: Node) => {
      event.preventDefault();
      const click = event.detail;
      setCommentNode({});
      if (menuRef.current) {
        const pane = menuRef.current.getBoundingClientRect();
        const posY = event.clientY - pane.top;
        const posX = event.clientX - pane.left;
        const key = `comment_${element.data.comments?.length < NUMBER.N2 ? element.data.comments?.length : 'max_heght'}`;
        const commentBoxHeight = element.data.comments?.length ? commentBoxHeightObj[key] : commentBoxHeightObj.comment_0;
        const commentBoxWidth = NUMBER.N280;
        setTimeout(() => {
          if (click === NUMBER.N1) {
            setCommentNode({
              selectedNode: element,
              id: element.id,
              top: posY < (pane.height - commentBoxHeight) ? posY : posY - commentBoxHeight,
              left: posX < (pane.width - commentBoxWidth) ? posX : posX - commentBoxWidth
            });
          } else {
            setCommentNode({});
          }
        }, NUMBER.N200);
      }
    }, [setCommentNode]);

  const onElementDoubleClick = useCallback((event: React.MouseEvent, element: Node) => {
    event.preventDefault();
    if (isSharedOrLockedScenario && !hasLayers(workbenchData.nodes, element.id)) {
      return;
    }
    const closestNodeContainer = (event.target as HTMLElement).closest('.node-container');
    if (closestNodeContainer) {
      closestNodeContainer.classList.add('node-active');
      setTimeout(() => {
        if (breadCrumb.length < WorkbenchLayersLimit && element.id) {
          const bc = [...breadCrumb, { id: element.id, name: element.data.componentDisplayName }];
          dispatch(updateBreadCrumb(bc));
          dispatch(updateCurrentLayerID(element.id));
        } else {
          toast.error(MESSAGES.LAYERS_LIMIT_EXCEEDED);
        }
        closestNodeContainer.classList.remove('node-active');
      }, NUMBER.N200);
    }
  }, [breadCrumb, currentLayerID, isSharedOrLockedScenario]);

  const undoWorkbench = () => {
    undo();
    updateNodeInternals(workbenchData.nodes.map((n) => { return n.id; }));
  };

  const redoWorkbench = () => {
    redo();
    updateNodeInternals(workbenchData.nodes.map((n) => { return n.id; }));
  };

  return (
    <>
      <div className='undo-redo'>
        <div className="tooltip-container undo">
          <Button className="setting-btn btn-no-outline" disabled={!canUndo || isSharedOrLockedScenario} onClick={undoWorkbench}>
            <div className="circle-logo-wrapper">
              <img src={undoIcon} alt="logo" className='icon icon-undo' />
            </div>
          </Button>
          <div className="tooltip-text">Undo</div>
        </div>
        <div className="tooltip-container redo">
          <Button className="setting-btn btn-no-outline" disabled={!canRedo || isSharedOrLockedScenario} onClick={redoWorkbench}>
            <div className="circle-logo-wrapper">
              <img src={undoIcon} alt="logo" className='icon icon-undo' />

            </div>
          </Button>
          <div className="tooltip-text">Redo</div>
        </div>
      </div>
      <div className={`reactflow-wrapper reactflow-main-container ${theme && 'light-theme'}`} ref={reactFlowWrapper}>
        <ReactFlow
          connectionLineType={ConnectionLineType.SmoothStep}
          connectionLineStyle={connectionLineStyle}
          nodes={nodes}
          edges={edges}
          ref={menuRef}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          onDragLeave={onDragEnd}
          onPaneClick={onPaneClick}
          onNodeContextMenu={onNodeContextMenu}
          defaultViewport={workbenchData.viewPort}
          onEdgesDelete={onEdgesDelete}
          onNodesDelete={onNodesDelete}
          onSelectionDragStart={onSelectionDragStart}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          nodeDragThreshold={NUMBER.N1}
          onMoveEnd={onMoveEnd}
          onEdgeClick={onEdgeClick}
          onEdgeUpdate={onEdgeUpdate}
          onNodeClick={onElementClick}
          onNodeDoubleClick={onElementDoubleClick}
          // disable the Workbench
          edgesUpdatable={!isSharedOrLockedScenario}
          panOnDrag={!isSharedOrLockedScenario}
          nodesDraggable={!isSharedOrLockedScenario}
          nodesConnectable={!isSharedOrLockedScenario}
          zoomOnScroll={!isSharedOrLockedScenario}
          zoomOnPinch={!isSharedOrLockedScenario}
          zoomOnDoubleClick={!isSharedOrLockedScenario}
          edgesFocusable={!isSharedOrLockedScenario}
          nodesFocusable={!isSharedOrLockedScenario}
          elementsSelectable={!isSharedOrLockedScenario}
        >
          {(commentNode.id && showCommentFlag) &&
            <CommentInput {...commentNode} commentNodeData={commentNodeData}
              setCommentUpdates={setCommentUpdates}
              commentUpdates={commentUpdates}
            />
          }
          {menu.id &&
            <ContextMenu
              onClick={onPaneClick} {...menu}
              showparameterpopup={() => showparameterpopup(menu.selectedNode)}
              takeSnapshot={takeNodesSnapshot}
            />
          }
          {!nodes?.length && <EmptyWorkbench />}
          {nodes?.length > NUMBER.N0 &&
            <Controls
              showFitView={false}
              showInteractive={false}
              showZoom={false}
            >
              <button className="last-update-btn">
                <DateTime createdDate={workbenchData.scenarioDetails.createDate} updatedDate={workbenchData.scenarioDetails.updateDate} />
              </button>
              <div className="workbench-footer-right">
                <div className={`zoomed-percentage ${zoomPercentage === NUMBER.N100 ? 'default-zoom' : ''}`}>Zoomed {zoomPercentage} %</div>
                <ControlButton onClick={() => zoomInOut('zoom_in')}>
                  <ZoomInOut className="icon-zoom-in" tooltip="Zoom in" zoomClass={zoomPercentage > NUMBER.N100 ? 'zoomed-in' : ''} image={zoomOutImg} />
                </ControlButton>
                <ControlButton onClick={() => zoomInOut('zoom_out')}>
                  <ZoomInOut className="icon-zoom-out" tooltip="Zoom out" zoomClass={zoomPercentage < NUMBER.N100 ? 'zoomed-out' : ''} image={zoomInImg} />
                </ControlButton>
                {userData.isDesiderAdmin && !isSharedOrLockedScenario && <ControlButton>
                  <div className="tooltip-container">
                    <div className="circle-logo-wrapper delete-btn" onClick={() => setShowDeleteScenarioAlert(true)}>
                      <span className="svg-icon">
                        <img src={deleteLogo} alt="logo img" />
                      </span>
                    </div>
                    <div className="tooltip-text up">Delete</div>
                  </div>
                </ControlButton>}
              </div>
            </Controls>
          }
        </ReactFlow>
      </div >
      {showDeleteScenarioAlert && <ConfirmationAlert
        showAlert={showDeleteScenarioAlert}
        title={LABELS.DELETE_SCENARIO}
        message={MESSAGES.DELETE_SCENARIO_CONFIRM_MESSAGE}
        yesBtnText={LABELS.YES_DELETE_SCENARIO}
        cancleBtnText={LABELS.NOT_NOW}
        onConfirm={() => removeRecentScenario()}
        onCancel={() => setShowDeleteScenarioAlert(false)}
      />}
    </>
  );
}

export default WorkbenchFlow;
